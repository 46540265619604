import React from "react";
import "../Component/Home.css";
import step1 from "../Component/image/step1.png";
import step2 from "../Component/image/step2.png";
import step3 from "../Component/image/step3.png";
import game1 from '../Component/image/7 up Down.png';
import game2 from '../Component/image/Ander Bahar Go.png'
import game3 from '../Component/image/Avitor.png'
import game4 from '../Component/image/Baccarat AB.png'
import game5 from '../Component/image/Car Roullete.png'
import game6 from '../Component/image/LUDO.png'
import video1 from '../Component/image/fuitslot.mp4'
import video2 from '../Component/image/dragonVStiger.mp4'
import video3 from '../Component/image/carroulette.mp4'
import video4 from '../Component/image/teenpatti.mp4'
import video5 from '../Component/image/americanroulette.mp4'
import { SiPaytm } from "react-icons/si";
import { FaCcPaypal } from "react-icons/fa6";
import { RiVisaLine } from "react-icons/ri";
import security from '../Component/image/100secured.webp'
import { FaInstagram } from "react-icons/fa6";
import { LuYoutube } from "react-icons/lu";
import { AiOutlineFacebook } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa6";
import { Link } from "react-router-dom";

export const Home = () => {
  const handleDownload = () => {
    // Replace 'yourfile.apk' with the name of your APK file
    const apkUrl = process.env.PUBLIC_URL + '/TeenpattiUltra.apk';
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = 'TeenpattiUltra.apk'; // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const referralCode = '12345'; 
  
  const handleDownloadClick = () => {
    const deepLink = `myapp://referral?code=${referralCode}`;
    window.location.href = deepLink;
  };

  return (
    <div style={{ marginTop: "10vh" }}>
      <div className="bannerCont">
        <div className="bannerCont1">
          <h4>Get up to</h4>
          <h1>₹100 WELCOME BONUS</h1>
          <button onClick={handleDownloadClick} download>
            JOIN
          </button>
        </div>
      </div>
      <div className="stepCont">
        <div className="text-center stepCont2">
          <p>Join in 3 Easy Steps</p>
        </div>
       <div className="stepContBox">
          <div className="stepContBox1">
            <img src={step1} alt=""/>
            <h4>JOIN</h4>
            <p>Quick & Easy</p>
          </div>
          <div className="stepContBox1">
            <img src={step2} alt=""/>
            <h4>CLAIM</h4>
            <p>Your Bonus</p>
          </div>
          <div className="stepContBox1">
            <img src={step3} alt=""/>
            <h4>PLAY</h4>
            <p>Play & Win</p>
          </div>
        </div>
      </div>
      <div className="topGameCont">
        <div className="text-center topGameContHead">
          <p>Top Games</p>
        </div>
        <div class="scroll-container">
          <div class="content">
            <div className="topContMain">
              <img src={game1} alt=""/>
              <h6>7 Up Down</h6>
            </div>
            <div className="topContMain">
              <img src={game2} alt=""/>
              <h6>Andar Bahar</h6>
            </div>
            <div className="topContMain">
              <img src={game3} alt=""/>
              <h6>Aviator</h6>
            </div>
            <div className="topContMain">
              <img src={game4} alt=""/>
              <h6>Baccarat AB</h6>
            </div>
            <div className="topContMain">
              <img src={game5}  alt=""/>
              <h6>Car Roullete</h6>
            </div>
            <div className="topContMain">
              <img src={game6} alt=""/>
              <h6>Ludo</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="bgRowCont">
   <div className="bgRowCont2">
      <div className="bgRowCont3">
                   <h1 style={{color:'#fcf6d5'}}>Play Top Games</h1>
       </div>
      <div className="bgRowCont4">
               <button onClick={handleDownload} download>PLAY</button>
               </div>
      </div>
      </div>
      <div className="ourExc">
        <div className="text-center ourExcContent">
          <p>Our Exlusive Tables</p>
        </div>
        <div class="scroll-container">
          <div class="content2">
            <div className="exclusiveMain">
            <video controls width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video1} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
              <h5 className="txtExc" style={{ color: "#bc3554", fontWeight: "bold" }}>Fruit Slot</h5>
            </div>
            <div 
              style={{height: "40vh",width: "100%",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center",marginRight:'25px'}}>
              <video controls width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video2} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
              <h5  className="txtExc"  style={{ color: "#bc3554", fontWeight: "bold" }}>Dragon Vs Tiger</h5>
            </div>
            <div style={{height: "30vh",width: "100%",display: "flex", flexDirection: "column",justifyContent: "center",alignItems: "center",marginRight:'25px'}}>
            <video controls width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video3} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
              <h5 className="txtExc"  style={{ color: "#bc3554", fontWeight: "bold" }}>Car Roullete</h5>
            </div>
            <div style={{height: "30vh",width: "100%",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center",marginRight:'25px' }}>
            <video controls width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video4} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
              <h5 className="txtExc"  style={{ color: "#bc3554", fontWeight: "bold" }}>Teenpatti</h5>
            </div>
            <div style={{height: "30vh",width: "100%",display: "flex",flexDirection: "column",justifyContent: "center", alignItems: "center",marginRight:'25px'}}>
            <video controls width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video5} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
              <h5  className="txtExc" style={{ color: "#bc3554", fontWeight: "bold" }}>American Roullete</h5>
            </div>
         </div>
        </div>
      </div>
      <div className="topPayment">
      <div className="text-center topPaymentHead">
          <p>
          Top Secure Payment Methods
          </p>
        </div>
        <div className="text-center topPaymentCont">
              <FaCcPaypal className="iconTxt"/>
              <SiPaytm  className="iconTxt"/>
              <RiVisaLine className="iconTxt"/>
        </div>
     </div>
      <div className="safeMainCont">
        <div  className="safeMainCont2">
                    <p>Safe, Secure & Award-Winning</p>
                    <img src={security} alt=""/>
               </div>
      </div> 
      <div className="onlineCasiCont">
      <div className="onlineCasi2">
      <h4>Online Casino</h4>
      <p>Online casinos have skyrocketed in popularity recently, offering players a convenient and accessible way to indulge in their favorite casino games from anywhere in the world. With just a few clicks, you can experience the thrill and excitement of the casino floor without ever leaving your home. Whether you're a fan of slots, poker, blackjack, or roulette, online casinos bring the action to your fingertips, allowing you to play anytime, anywhere.</p>
       <div className="onlineCasi3">
             <p style={{borderRight:'1px solid #826458',width:'6%',color:'#826458'}}><Link to='/' style={{textDecoration:'none',color:'#826458'}}>Home</Link></p>
             <p style={{borderRight:'1px solid #826458',width:'8%',color:'#826458'}}><Link to='/about' style={{textDecoration:'none',color:'#826458'}}>About us</Link></p>
             <p style={{borderRight:'1px solid #826458',width:'14%',color:'#826458'}}><Link to='/terms' style={{textDecoration:'none',color:'#826458'}}>Terms & Conditions</Link></p>
             <p style={{borderRight:'1px solid #826458',width:'10%',color:'#826458'}}><Link to='/privacy' style={{textDecoration:'none',color:'#826458'}}>Privacy Policy</Link></p>
             <p style={{borderRight:'1px solid #826458',width:'23%',color:'#826458'}}><Link to='/cancell' style={{textDecoration:'none',color:'#826458'}}>Cancellation and Refund Policy</Link></p>
             <p style={{borderRight:'1px solid #826458',width:'8%',color:'#826458'}}><Link to='/pricing' style={{textDecoration:'none',color:'#826458'}}>Pricing</Link></p>
             <p style={{width:'10%',color:'#826458',}}><Link to='/cont' style={{textDecoration:'none',color:'#826458'}}>Contact Us</Link></p>
      </div>
      <div className="onlineCasi3A">
      <div style={{height:'6vh',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
      <p style={{borderRight:'1px solid #826458',width:'20%',color:'#826458'}}>Home</p>
      <p style={{borderRight:'1px solid #826458',width:'24%',color:'#826458'}}>About us</p>
      <p style={{width:'18%',color:'#826458'}}>Pricing</p>
      </div>
      <div style={{height:'6vh',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
      <p style={{borderRight:'1px solid #826458',width:'45%',color:'#826458'}}>Terms & Conditions</p>
      <p style={{width:'30%',color:'#826458'}}>Privacy Policy</p>
      </div>
      <div style={{height:'6vh',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
      <p style={{width:'70%',color:'#826458'}}>Cancellation and Refund Policy</p>
      </div>
      </div>
      </div>
      </div> 
      <div className="copyCont">
     <div className="copyCont1">Copyright 2023 rummyjio.com All rights reserved</div>
     <div className="copyCont2">
     <FaInstagram className="textCopy"/>
     <LuYoutube className="textCopy"/>
     <AiOutlineFacebook className="textCopy"/>
     <FaTelegram className="textCopy"/>
     </div>
      </div>
    </div>
  );
};
