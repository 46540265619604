import React from 'react'
import { Navbar } from './Navbar'
import { Home } from './Home'

export const Main = () => {
  return (
    <>
       <Navbar/>
       <Home/>
       </>
  )
}
