import React from 'react';
import { Allroutes } from './Component/Allroutes';

function App() {
  return (
    <>
       <Allroutes/>
    </>
  );
}

export default App;
