import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Main } from './Main'
import { AboutUs } from './ExtraPages/AboutUs'
import { Cancellation } from './ExtraPages/Cancellation'
import { Contact } from './ExtraPages/contact'
import { Pricing } from './ExtraPages/Pricing'
import { Privacy } from './ExtraPages/Privacy'
import { Terms } from './ExtraPages/Terms'

export const Allroutes = () => {
  return (
    <Routes>
    <Route exact path='/' element={<Main/>}></Route>
    <Route exact path='/about' element={<AboutUs/>}></Route>
    <Route exact path='/cancell' element={<Cancellation/>}></Route>
    <Route exact path='/cont' element={<Contact/>}></Route>
    <Route exact path='/pricing' element={<Pricing/>}></Route>
    <Route exact path='/privacy' element={<Privacy/>}></Route>
    <Route exact path='/terms' element={<Terms/>}></Route>
 </Routes>
  )
}
