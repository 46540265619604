import React from "react";

export const Navbar = () => {
  // const handleDownload = () => {
  //   // Replace 'yourfile.apk' with the name of your APK file
  //   const apkUrl = process.env.PUBLIC_URL + '/TeenpattiUltra.apk';
  //   // Create a temporary link element
  //   const link = document.createElement('a');
  //   link.href = apkUrl;
  //   link.download = 'TeenpattiUltra.apk'; // Specify the file name
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  // const referralCode = '12345'; 
  // const handleDownloadClick = () => {
  //   const deepLink = `myapp://referral?code=${referralCode}`;
  //   const apkDownloadLink = process.env.PUBLIC_URL + '/DeepLink.apk'; // Your APK link
  
  //   // Check if the app is installed (using deep link), else download APK
  //   window.location.href = deepLink;
  //   setTimeout(() => {
  //     window.location.href = apkDownloadLink;
  //   }, 2000); // APK download as a fallback if app isn't installed
  // };

  // const handleDownloadClick = async () => {
  //   // Log browser and network details
  //   console.log(navigator.userAgent);
  //   console.log(navigator.language);
  //   console.log(window.screen.width + 'x' + window.screen.height);
  //   console.log(window.innerWidth + 'x' + window.innerHeight);
  //   console.log(navigator.platform);
  //   console.log(navigator.connection.effectiveType); // Network type
    
  //   // Log battery level
  //   navigator.getBattery().then(function(battery) {
  //     console.log(battery.level * 100 + '%');
  //   });
  
  //   // Log the user's IP address using a third-party service
  //   fetch('https://ipinfo.io/json')
  //     .then(response => response.json())
  //     .then(data => console.log('IP Address:', data.ip));
  
  //   // Set a cookie with a path for your web app (not a file path)
  //   document.cookie = "referralCode=12345; path=/";
  
  //   console.log(document.cookie);
    
  //   // You can also set additional cookie attributes, like expiry
  //   document.cookie = "referralCode=12345; path=/; max-age=3600"; // Expires in 1 hour
  // };

  // const getDynamicUrl = () => {
  //   // Example logic to get a dynamic URL
  //   // This could be based on user input, configuration, or other sources
  //   const inputElement = document.getElementById('urlInput');
  //   return inputElement ? inputElement.value.trim() : 'http://localhost:3000/?default';
  // };

  const handleDownloadClick = async () => {
    // // Function to get the base URL and referral code from the current URL
    // const getBaseUrl = () => {
    //   // Get the base URL (excluding query parameters)
    //   return window.location.origin + window.location.pathname;
    // };
    
    // const getReferralCodeFromUrl = () => {
    //   // Get URL parameters
    //   const urlParams = new URLSearchParams(window.location.search);
    //   return urlParams.get('referralCode'); // Return null if not present
    // };
    
    // // Get base URL and referral code
    // const baseUrl = getBaseUrl();  
    // const referralCode = getReferralCodeFromUrl();
    
    // // Construct the URL with the referral code
    // const url = referralCode ? `${baseUrl}?referralCode=${encodeURIComponent(referralCode)}` : baseUrl;
    
    // // Extract the code after '?' if present
    // const queryString = window.location.search; // Get the query string part
    // const code = queryString ? queryString.substring(1) : ''; // Extract substring after '?', remove '?'
    
    // // Log the extracted code and constructed URL
    // console.log("Extracted Code:", code);
    // console.log("Constructed URL:", url);
// First fetch to get the public IP
fetch('https://api.ipify.org?format=json')
  .then(response => response.json())
  .then(data => {
      const dataip = data.ip;
      console.log('Your IP Address is:', dataip);

      // Get the promocode from the URL
      const url = window.location.href;
      const urlParams = new URLSearchParams(window.location.search);
   // Construct the URL with the referral code
    
   // Extract the code after '?' if present
   const queryString = window.location.search; // Get the query string part
   const promocode = queryString ? queryString.substring(1) : ''; // Extract substring after '?', remove '?'
         console.log('Promocode:', promocode);

      // Prepare the data for the POST request
      const postData = new URLSearchParams();
      postData.append('ip', dataip);
      postData.append('promocode', promocode);

      // Now send the POST request after IP is fetched
      fetch('https://casinokinggame.com/api/getipaddress.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: postData // The data to send
      })
      .then(response => response.json()) // Parse JSON response
      .then(result => {
        console.log('Success:', result);
      })
      .catch(error => {
        console.error('Error in POST request:', error);
      });

  })
  .catch(error => {
    console.error('Error fetching the IP address:', error);
  });
    // Log browser and network details
    console.log("User Agent:", navigator.userAgent);
    console.log("Language:", navigator.language);
    console.log("Screen Resolution:", window.screen.width + 'x' + window.screen.height);
    console.log("Viewport Size:", window.innerWidth + 'x' + window.innerHeight);
    console.log("Platform:", navigator.platform);
  
    // // Fetch the IP address from a third-party service (or you can try to get it directly from the API you're using)
    // try {
    //   const ipResponse = await fetch('https://ipinfo.io/json'); // External service to get the IP
    //   const ipData = await ipResponse.json();
    //   const ipAddress = ipData.ip; // Assuming the response contains { "ip": "xx.xx.xx.xx" }
    //   console.log("Fetched IP Address from service:", ipAddress);
  
    //   // Now send the IP address to the API to store it
    //   const storeIpResponse = await fetch('https://casinokinggame.com/api/getipaddress.php', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       ip: ipAddress,  // Send the IP address to store
    //       promocode : code
    //     }),
    //   });
  
    //   const result = await storeIpResponse.json();
    //   console.log("hum : " + storeIpResponse);
    //   console.log("IP stored result:", result); // Log the result from storing the IP
  
    // } catch (error) {

    //   console.error("Error fetching or storing IP address:", error);
    // }
  
    // Set a cookie with a path for your web app (not a file path)
    document.cookie = "referralCode=12345; path=/";
    console.log("Cookies after setting:", document.cookie);
  
    // Set additional cookie attributes like expiry
    document.cookie = "referralCode=12345; path=/; max-age=3600"; // Expires in 1 hour
    console.log("Cookies after expiry setting:", document.cookie);

     // Trigger APK download
  const apkUrl = process.env.PUBLIC_URL + '/Casino King Game.apk'; // Replace with your APK URL
  const link = document.createElement('a');
  link.href = apkUrl;
  link.download = 'Casino King Game.apk'; // Optional: Set the filename for the downloaded APK
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  };
  
  
  return (
    <div>
      <nav class="navbar  fixed-top" style={{background:'#fcf6d5',height:'10vh'}}>
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            Offcanvas navbar
          </a>
         <button style={{backgroundColor:'#bc3554',borderColor:'#bc3554',borderRadius:'4px',border:'none',fontSize:'14px',padding: '8px 15px',color:'#fcf6d5'}} onClick={handleDownloadClick} download>DOWNLOAD APK</button>
        </div>
      </nav>
    </div>
  );
};
